import React from "react";
import Layout from "../components/Layout";
import ReactHelmet from "react-helmet";
import ContactSection from "../components/sections/ContactSection";
import Section from "../components/sections/Section";
import styled from "styled-components";
import { DuckImage, H2, Text } from "../components/Common";
import { graphql, useStaticQuery } from "gatsby";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";

const NotFoundSection = styled(Section)``;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bananaduck.png" }) {
        childImageSharp {
          gatsbyImageData(width: 329)
        }
      }
    }
  `);

  return (
    <Layout mainPage header={<Header variant="dark" />} footer={<Footer />}>
      <ReactHelmet
        title="Not Found"
        meta={[
          {
            name: "description",
            content: "This page is not found",
          },
        ]}
      />
      <NotFoundSection>
        <NotFoundContainer>
          <H2>This page is not found</H2>
          <DuckImage fluid={data.file.childImageSharp.fluid} />
          <Text>But with AppSpector you can found all bugs</Text>
        </NotFoundContainer>
      </NotFoundSection>
      <ContactSection />
    </Layout>
  );
};

export default NotFoundPage;
